import CountrySliderImg1 from "./images/countrySliderImg1.jpg"
import CountrySliderImg2 from "./images/countrySliderImg2.jpg"
import CountrySliderImg3 from "./images/countrySliderImg3.png"
import CountrySliderImg4 from "./images/countrySliderImg4.jpg"
import CountrySliderImg5 from "./images/countrySliderImg5.jpg"


export const countrySliderData = [
    CountrySliderImg1,
    CountrySliderImg2,
    CountrySliderImg3,
    CountrySliderImg4,
    CountrySliderImg5
]