import { glanceData } from "./data"
import { Fade } from "react-reveal"

function Glance() {
    return (
        <div className="2xl:max-w-[1360px] 2xl:mx-auto">
            <div className="font-serif my-[5rem] mx-[2rem]
        sm:mx-[5rem] sm:my-[15rem]
        md:mx-[8rem]
        ">
                <div className="text-[2rem] font-bold text-center text-[#6CAAA8] w-full">
                    <p>What we focus on our company glance</p>
                </div>
                <div className="flex flex-row justify-around flex-wrap">
                    {
                        glanceData.map((ele, i) => {
                            return <Fade bottom><div key={i} className="flex flex-col mt-[5rem] w-[400px] shadow-md
                        md:w-[280px]
                        lg:w-[335px]
                        ">
                                <div>
                                    <img
                                        src={ele.img}
                                        alt="error"
                                        className="w-full h-[150px]"
                                    />
                                </div>
                                <div className="p-[1.2rem] flex flex-col gap-[1rem]">
                                    <p className="font-bold text-[#6CAAA8] text-[1.3rem]">{ele.heading}</p>
                                    <p className="text-slate-400">{ele.content}</p>
                                </div>
                            </div>
                            </Fade>
                        })
                    }
                </div>
            </div>
        </div>
    )
}

export default Glance
