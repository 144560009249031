import { Link } from "react-router-dom";
import { certificateData } from "./data"

function Certificate() {
    return (
        <div className="m-[2rem] flex flex-col gap-[2rem]
        sm:m-[8rem]
        2xl:max-w-[1360px] 2xl:mx-auto
        ">
            <div className="font-serif font-bold text-[2rem] text-[#6CAAA8] text-center">
                <p>Certificates</p>
            </div>
            <div className="flex flex-wrap justify-center gap-[2rem] items-center
            xl:flex-nowrap xl:justify-between xl:gap-[0rem]
            ">
                {
                    certificateData.map((ele, i) => {
                        return <Link to={ele.path} target="_blank"><div>
                            <img
                                src={ele.name}
                                alt="error"
                                key={i}
                                className="w-full h-[30rem]"
                            />
                        </div></Link>
                    })
                }
            </div>
        </div>
    )
}

export default Certificate;
