import Header from "./components/header/Header";
import Home from "./pages/home/Home";
import Footer from "./components/footer/Footer";
import Ayurveda from "./pages/ourBusiness/ayurveda/Ayurveda";
import Nutraceutical from "./pages/ourBusiness/nutraceutical/Nutraceutical";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import { useEffect } from "react";
import About from "./pages/about/About";
import PatentMedicine from "./pages/products/patentMedicine/PatentMedicine";
import ClassicalMedicine from "./pages/products/classicalMedicine/ClassicalMedicine";
import PureHerbs from "./pages/products/pureHerbs/PureHerbs";
import Operations from "./pages/operations/Operations";
import Contact from "./pages/contact/Contact";
import ScrollUp from "./components/scrollUp/ScrollUp";
import DrawerNavbar from "./components/drawerNavbar/DrawerNavbar";


function App() {

  function GoToTop() {
    const routePath = useLocation();
    const onTop = () => {
      window.scrollTo(0, 0);
    }
    useEffect(() => {
      onTop()
    }, [routePath]);
    return null;
  }

  return (
    <div>
      <BrowserRouter>
        <GoToTop />
        <Header />
        <DrawerNavbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/ourBusiness/ayurveda" element={<Ayurveda />} />
          <Route path="/ourBusiness/Nutraceutical" element={<Nutraceutical />} />
          <Route path="/products/patentMedicine" element={<PatentMedicine />} />
          <Route path="/products/classicalMedicine" element={<ClassicalMedicine />} />
          <Route path="/products/pureHerbs" element={<PureHerbs />} />
          <Route path="/operations" element={<Operations />} />
          <Route path="/contactUs" element={<Contact />} />
        </Routes>
        <ScrollUp />
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
