import { Cursor, useTypewriter } from 'react-simple-typewriter'
import homeBannerImg from "./homeBannerImg.jpg"
import homeBannerImg3 from "./homeBannerImg3.jpg"


function HomeBanner() {

    const textArray = ["and healthcare professionals."]

    let [typeEffect, { isDeleting }] = useTypewriter({
        words: textArray,
        loop: {},
        typeSpeed: 100,
        deleteSpeed: 40,
        cursor: true,
    })

    return (
        <div>
            <div className="relative">
                <img
                    src={homeBannerImg}
                    alt="error"
                    className='w-full lg:inline hidden'
                />
                <img
                    src={homeBannerImg3}
                    alt="error"
                    className='inline lg:hidden'
                />
                <div className="absolute font-serif inset-0 flex flex-col items-center justify-center gap-[1.2rem] w-full px-[2rem]
                sm:mx-[0rem] sm:pr-[0rem]
                lg:pr-[7rem] lg:items-end
                ">
                    <p className="w-full text-[1.5rem] font-semibold break-all
                    sm:w-[38rem] sm:font-font-bold sm:text-[2.5rem]
                    ">Trusted Pharma Experts and Latest Pharma Technologies</p>
                    <p className="w-full text-[1.02rem] text-justify break-all hidden
                    sm:w-[38rem] sm:inline
                    "><span className="text-[#2e7775] text-[1.2rem] font-semibold">ASHWAN HEALTHCARE</span> has state of art manufcturing of well qualified with modern technology of well qualified professionals underguidance. Pharmaceutical companies often form medical advisory boards consisting of experienced doctors, researchers, {typeEffect} {isDeleting ? "" : <Cursor cursorStyle="|" />}</p>
                </div>


            </div>
        </div>
    )
}

export default HomeBanner;