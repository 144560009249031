import pureHerbsImg1 from "./images/pureHerbsImg1.webp"
import pureHerbsImg2 from "./images/pureHerbsImg2.webp"
import pureHerbsImg3 from "./images/pureHerbsImg3.webp"
import pureHerbsImg4 from "./images/pureHerbsImg4.webp"
import pureHerbsImg5 from "./images/pureHerbsImg5.webp"
import pureHerbsImg6 from "./images/pureHerbsImg6.webp"
import pureHerbsImg7 from "./images/pureHerbsImg7.webp"
import pureHerbsImg8 from "./images/pureHerbsImg8.webp"
import pureHerbsImg9 from "./images/pureHerbsImg9.webp"
import pureHerbsImg10 from "./images/pureHerbsImg10.webp"
import pureHerbsImg11 from "./images/pureHerbsImg11.webp"


export const productsAndTableData = [
    {
        img: pureHerbsImg1,
        heading: "HARIDRA NAINO CARCUMIN",
        tableData: [
            {
                name: "Haldi Ext.(curcuma longa)",
                value: "750mg"
            }
        ]
    },
    {
        img: pureHerbsImg2,
        heading: "ASHWAGANDHA TABLETS",
        tableData: [
            {
                name: "Ashwagandha Ext.(Withania somnifera)Root",
                value: "500mg"
            }
        ]
    },
    {
        img: pureHerbsImg3,
        heading: "TULSI TABLETS",
        tableData: [
            {
                name: "Tulsi Ext.(Ocimum sanctum)Leaf",
                value: "750mg"
            }
        ]
    },
    {
        img: pureHerbsImg4,
        heading: "GILOY Tablets",
        tableData: [
            {
                name: "Giloy Ext.(Tinospora cordifolia)Steam",
                value: "500mg"
            }
        ]
    },
    {
        img: pureHerbsImg5,
        heading: "TRIPHALA JUICE",
        tableData: [
            {
                name: "Amalaki (Emblica officinalls)",
                value: "33.3%"
            },
            {
                name: "Haritaki (Terninalia chebula)",
                value: "33.3%"
            },
            {
                name: "Vibhitaki (Terminalia bellirica)",
                value: "33.3%"
            },
            {
                name: "Preservative Sodium benzoate",
                value: "0.1%"
            }
        ]
    },
    {
        img: pureHerbsImg6,
        heading: "NONI JUICE",
        tableData: [
            {
                name: "Each 10 MI. contains extract derived from Noni (Morinda citrifolia)",
                value: "9.9ml"
            },
            {
                name: "Sodium Benzoate",
                value: "0.1%"
            }
        ]
    },
    {
        img: pureHerbsImg7,
        heading: "PAPAYA PATTA GILOY RAS",
        tableData: [
            {
                name: "Papita Patta (Carica Papaya)",
                value: "6.0%"
            },
            {
                name: "Giloy (tinospora Cordifolia)",
                value: "3.9%"
            },
            {
                name: "Sodium Benzoate (Preservative) Dosage: 10 to 20 ml.with ½ cup Water",
                value: "0.1%"
            }
        ]
    },
    {
        img: pureHerbsImg8,
        heading: "Amla Juice",
        tableData: [
            {
                name: "Amla (Emblica officinalis)",
                value: "9.9%"
            },
            {
                name: "Sodium Benzoate (Preservative) Dosage: 10 to 20 ml.with ½ cup Water",
                value: "0.1%"
            }
        ]
    },
    {
        img: pureHerbsImg9,
        heading: "GILOY RAS",
        tableData: [
            {
                name: "Giloy (tinospora Cordifolia)",
                value: "9.9%"
            },
            {
                name: "Sodium Benzoate (Preservative)",
                value: "0.1%"
            }
        ]
    },
    {
        img: pureHerbsImg10,
        heading: "GILOY TULSI AMALA RAS",
        tableData: [
            {
                name: "Giloy (tinospora Cordifolia)",
                value: "3.33%"
            },
            {
                name: "Tulsi (Ocimum sanctum)",
                value: "3.33%"
            },
            {
                name: "Amla (Emblica officinalis)",
                value: "3.33%"
            },
            {
                name: "Sodium Benzoate (Preservative)",
                value: "0.1%"
            }
        ]
    },
    {
        img: pureHerbsImg11,
        heading: "Neem Jamun Karela Juice",
        tableData: [

            {
                name: "Amalaki(Emblica officinalis)",
                value: "3.33%"
            },
            {
                name: "Haritaki (Terninalia chebula)",
                value: "3.33%"
            },
            {
                name: "Vibhitaki (Terminalia bellirica)",
                value: "3.33%"
            },
            {
                name: "Sodium Benzoate (Preservative)",
                value: "0.1%"
            }
        ]
    },
]