import ayurvedaProductImg1 from "./images/ayurvedaProductImg1.webp"
import ayurvedaProductImg2 from "./images/ayurvedaProductImg2.webp"
import ayurvedaProductImg3 from "./images/ayurvedaProductImg3.webp"
import ayurvedaProductImg4 from "./images/ayurvedaProductImg4.webp"
import ayurvedaProductImg5 from "./images/ayurvedaProductImg5.webp"
import ayurvedaProductImg6 from "./images/ayurvedaProductImg6.webp"
import ayurvedaProductImg7 from "./images/ayurvedaProductImg7.webp"
import ayurvedaProductImg8 from "./images/ayurvedaProductImg8.webp"
import ayurvedaProductImg9 from "./images/ayurvedaProductImg9.webp"
import ayurvedaProductImg10 from "./images/ayurvedaProductImg10.webp"
import ayurvedaProductImg11 from "./images/ayurvedaProductImg11.webp"
import ayurvedaProductImg12 from "./images/ayurvedaProductImg12.webp"
import ayurvedaProductImg13 from "./images/ayurvedaProductImg13.webp"
import ayurvedaProductImg14 from "./images/ayurvedaProductImg14.webp"
import ayurvedaProductImg15 from "./images/ayurvedaProductImg15.webp"
import ayurvedaProductImg16 from "./images/ayurvedaProductImg16.webp"
import ayurvedaProductImg17 from "./images/ayurvedaProductImg17.webp"
import ayurvedaProductImg18 from "./images/ayurvedaProductImg18.webp"
import ayurvedaProductImg19 from "./images/ayurvedaProductImg19.webp"
import ayurvedaProductImg20 from "./images/ayurvedaProductImg20.webp"
import ayurvedaProductImg21 from "./images/ayurvedaProductImg21.webp"
import ayurvedaProductImg22 from "./images/ayurvedaProductImg22.webp"
import ayurvedaProductImg23 from "./images/ayurvedaProductImg23.webp"


export const productsAndTableData = [
    {
        img: ayurvedaProductImg1,
        heading: "ASTOLIVE SYRUP",
        tableData: [
            {
                name: "Bhringraj (Eclipta alba)(Whole plant)",
                value: "500mg"
            },
            {
                name: "Rakt Punarnava (Phyllanthus niruri)(Root)",
                value: "200mg"
            },
            {
                name: "Bhumiamla (Phyllanthus niruri)(Whole plant)	",
                value: "600mg"
            },
            {
                name: "Kutaki (Picrorhiza Kurroa) (Root)",
                value: "160mg"
            },
            {
                name: "Chitrak (Plumbago zeylanica)(Root)",
                value: "200mg"
            },
            {
                name: "Trikatu (C.Yog) (Fruit)",
                value: "200mg"
            },
            {
                name: "Kalmegh (Andrographis paniculata)(Whole plant)",
                value: "200mg"
            },
            {
                name: "Apamarg (Achyranthes aspera)(Whole plant)",
                value: "200mg"
            },
            {
                name: "Giloy (Tinospora cordifolia)(stem)",
                value: "500mg"
            },
            {
                name: "Sarphuka (Tephrosiapurpurya)(Whole plant)",
                value: "500mg"
            }
        ]
    },
    {
        img: ayurvedaProductImg2,
        heading: "ASTOFEM SYRUP",
        tableData: [
            {
                name: "Ashok (Saraca indica) Bark",
                value: "500mg"
            },
            {
                name: "Lodhra (Symplocos racemosa) Bark",
                value: "500mg"
            },
            {
                name: "Satawar (Asparagus racemosus) Root",
                value: "200mg"
            },
            {
                name: "Ashwagandha (Withania somnifera) Root",
                value: "200mg"
            },
            {
                name: "Manjishth (Rubia cordifolia) Root",
                value: "200mg"
            },
            {
                name: "Daruhaldi (Berberis aristata) Stem Root",
                value: "300mg"
            },
        ]
    },
    {
        img: ayurvedaProductImg3,
        heading: "CYPRUS-D",
        tableData: [
            {
                name: "Laung (Caryophyllusarom aticus) Flower",
                value: "50mg"
            },
            {
                name: "Haritki (Terminaliachebula) Fruit",
                value: "300mg"
            },
            {
                name: "Mushtak (Cyprus rotandus) Root",
                value: "100mg"
            },
            {
                name: "Yestimadhu (Glycyrrhizaglabra)Root",
                value: "250mg"
            },
            {
                name: "Kakarsinghi (Pistacia integerrima)Gall",
                value: "250mg"
            },
            {
                name: "Soauth (Zingiberofficinale)Rhizome",
                value: "250mg"
            },
            {
                name: "Pipli (Piper longum)Fruit",
                value: "250mg"
            },
            {
                name: "Marich (Pipernigrum) Fruit",
                value: "250mg"
            },
            {
                name: "Tulsi (Ocimum sancctum)Leaf",
                value: "250mg"
            },
            {
                name: "Shahad (Nector) Animal Product",
                value: "250mg"
            },
            {
                name: "Vasa (Adhatodavasical) Leaf",
                value: "500mg"
            },
            {
                name: "Kanthari (Solanum surattense) Whole plant",
                value: "500mg"
            }
        ]
    },
    {
        img: ayurvedaProductImg4,
        heading: "HYZEST SYRUP",
        tableData: [
            {
                name: "Chitrak (Plumbago zeylanica)(Root)",
                value: "50mg"
            },
            {
                name: "Nagarmotha (Cyperus scariosus)(Root)",
                value: "100mg"
            },
            {
                name: "Adrak Juice (Zingiber officinale)(Rhizome)",
                value: "50mg"
            },
            {
                name: "Pippali (Piper longum)(Flower bud)",
                value: "10mg"
            },
            {
                name: "Dhaniya (Coriandrum Sativum)(Fruit)",
                value: "50mg"
            },
            {
                name: "Ajowain (Carum Copticum)(Fruit)",
                value: "50mg"
            },
            {
                name: "Haritaki (Terminalia chebula)(Friot)",
                value: "50mg"
            },
            {
                name: "Kala Namak (Unaqua sodium chloride)(Mineral)",
                value: "50mg"
            },
            {
                name: "Vai-vidang (Embelia ribe)(Fruit)",
                value: "25mg"
            },
            {
                name: "Hing (Ferula narthex)(Niryas)",
                value: "5mg"
            }
        ]
    },
    {
        img: ayurvedaProductImg5,
        heading: "ASTOPAIN OIL",
        tableData: [
            {
                name: "Mahanarayan Oil (Classical yog)",
                value: "20%"
            },
            {
                name: "Mahavishgarbh Oil(Classical yog)",
                value: "15 %"
            },
            {
                name: "Nirgundi(Vitex negundo)(Leaf)",
                value: "10%"
            },
            {
                name: "Turpentine Oil(Pinus longifolia)(Resin)",
                value: "5%"
            },
            {
                name: "Nilgiri Oil(Eucalyptus globules)(Leaf)",
                value: "5%"
            },
            {
                name: "Gandhpuro Oil(Gaultheria fragrantissima)(Whole plant)",
                value: "5%"
            },
            {
                name: "Malkangani Oil(Celastrus paniculatus)(seed)",
                value: "5%"
            },
            {
                name: "Erand Oil(Ricinus cummunis)(Seed)",
                value: "5%"
            },
            {
                name: "Kapoor(Cinnamomum camphora)(Wood Ext.)",
                value: "5%"
            },
            {
                name: "Capsicum Oil Ext.(Capsicum annum) Fruit",
                value: "5%"
            },
        ]
    },
    {
        img: ayurvedaProductImg6,
        heading: "IMMUNCAD TABLETS",
        tableData: [
            {
                name: "Giloy Ext. (Tinospora cordifolia)",
                value: "250mg"
            },
            {
                name: "Haldi Ext. (Curcuma longa)",
                value: "150mg"
            },
            {
                name: "Tulsi Ext. (Ocimum Sanctum)",
                value: "25mg"
            },
            {
                name: "Sonth Ext. (Zingiber oficinale)",
                value: "55mg"
            },
            {
                name: "Mulethi Ext. (Glycyrrhiza glabra)",
                value: "100mg"
            },
            {
                name: "Ashwagandha Ext. (Withania somnifera)",
                value: "150mg"
            },
            {
                name: "Dalchini Ext. (Cinnamomum zeylanicum)",
                value: "25mg"
            },
            {
                name: "Kali Mirch Ext. (Piper nigrum)",
                value: "25mg"
            }
        ]
    },
    {
        img: ayurvedaProductImg7,
        heading: "TULSI DROPS",
        tableData: [
            {
                name: "Shyam Tulsi (occimum basilicum)",
                value: "20%"
            },
            {
                name: "Rama Tulsi (occimum sanctum)",
                value: "20%"
            },
            {
                name: "Sursa Tulsi (occimumcitriodorum)",
                value: "20%"
            },
            {
                name: "Nimbu Tulsi (occimumcitridorum)",
                value: "20%"
            },
            {
                name: "Van Tulsi (occimumgratissimum)",
                value: "20%"
            },
        ]
    },
    {
        img: ayurvedaProductImg8,
        heading: "THYMOL PLUS DROPS",
        tableData: [
            {
                name: "AJWAIN(Trachyspermum ammi)",
                value: "50%"
            },
            {
                name: "PUDINA(Mentha arvensis)",
                value: "27%"
            },
            {
                name: "JEERA(Cuminum cyminum)",
                value: "18%"
            },
            {
                name: "SAUNF(Foeniculum vulgare)",
                value: "4.9%"
            },
            {
                name: "ELAICHI(Elettaria cardamomum)",
                value: ".1%"
            }
        ]
    },
    {
        img: ayurvedaProductImg9,
        heading: "THYMOL-IBS DROPS",
        tableData: [
            {
                name: "Ajwain(Trachyspermum ammi)",
                value: "31.66%"
            },
            {
                name: "Pudina(Mentha arvensis)",
                value: "31.66%"
            },
            {
                name: "Bhimseni kapoor(Cinnamomum Camphora)",
                value: "31.66%"
            },
            {
                name: "Jeera(Cuminum cyminum)",
                value: "4.9%"
            },
            {
                name: "Elaichi(Elettaria cardamomum)",
                value: ".1%"
            }
        ]
    },
    {
        img: ayurvedaProductImg10,
        heading: "IMMUNCAD DROPS",
        tableData: [
            {
                name: "Tulsi (Ocimum Sanctum )",
                value: "40%"
            },
            {
                name: "Haldi(Curcuma longa)",
                value: "40%"
            },
            {
                name: "Dalchini(Cinnamomum Verum)",
                value: "10%"
            },
            {
                name: "Ajwain(Trachyspermum ammi)",
                value: "6.5%"
            }
        ]
    },
    {
        img: ayurvedaProductImg11,
        heading: "ARISTA-DB",
        tableData: [
            {
                name: "Vijaysar(Pterocarpus marsupium)",
            },
            {
                name: "Daruhaldi(Berberis aristata)",
            },
            {
                name: "Meshshringi(Gymnema sylvestre)",
            },
            {
                name: "Jamun(Eugenia jambolana)",
            },
            {
                name: "Methi(Trigonella foenum - graecum)",
            },
            {
                name: "Neem(Azadirachta indica)",
            },
            {
                name: "Giloy(Tinospora cordifolia)",
            },
            {
                name: "Karela(Momordica charantia)",
            },
            {
                name: "Amlaki(Emblica officinalis)",
            }
        ]
    },
    {
        img: ayurvedaProductImg12,
        heading: "SMILEX-PURE",
        tableData: [
            {
                name: "Smilax zeylanica (Chopchini)",
                value: "5%"
            },
            {
                name: "Sphaeranthus indicus (Gorakh mundi )",
                value: "20%"
            },
            {
                name: "Hemidesmus indicus (Anant mool)",
                value: "15%"
            },
            {
                name: "Rubia cordifolia (manjistha)",
                value: "15%"
            },
            {
                name: "Curcuma longa (Haldi)",
                value: "5%"
            }
        ]
    },
    {
        img: ayurvedaProductImg13,
        heading: "GLOW-X SOAP",
        tableData: [
            {
                name: "HALDI"
            },
            {
                name: "NEEM"
            },
            {
                name: "LEMON GRASS"
            },
            {
                name: "GLYCERIN"
            }
        ]
    },
    {
        img: ayurvedaProductImg14,
        heading: "AYUSH KWATH",
        tableData: [
            {
                name: "Tulsi (Ocimum sanctum)",
                value: "1336mg"
            },
            {
                name: "Dalchini (Cinnamomum zeylanicum)",
                value: "668mg"
            },
            {
                name: "Sunthi (Zingiber officinale)",
                value: "668mg"
            },
            {
                name: "Krishna Marich (Piper nigrum)",
                value: "334mg"
            }
        ]
    },
    {
        img: ayurvedaProductImg15,
        heading: "RENOL-P",
        tableData: [
            {
                name: "Pashanbhed",
                value: "150mg"
            },
            {
                name: "Gokhru",
                value: "100mg"
            },
            {
                name: "Varun",
                value: "80mg"
            },
            {
                name: "Lajjalu",
                value: "80mg"
            },
            {
                name: "Chhadila",
                value: "80mg"
            },
            {
                name: "Shilajit",
                value: "60mg"
            },
            {
                name: "Hazrul yahud bhasm",
                value: "60mg"
            }
        ]
    },
    {
        img: ayurvedaProductImg16,
        heading: "OXIDANTO",
        tableData: [
            {
                name: "Aswagandha Ext.",
                value: "200mg"
            },
            {
                name: "Satavari",
                value: "150mg"
            },
            {
                name: "Safedmusli",
                value: "125mg"
            },
            {
                name: "Gokshura",
                value: "100mg"
            }
        ]
    },
    {
        img: ayurvedaProductImg17,
        heading: "TRIPNERVE",
        tableData: [
            {
                name: "Papita Patta Ext.",
                value: "750mg"
            },
            {
                name: "Giloy Ext.",
                value: "250mg"
            },
            {
                name: "Kalmegh Ext.",
                value: "100mg"
            }
        ]
    },
    {
        img: ayurvedaProductImg18,
        heading: "ASTOLAX",
        tableData: [
            {
                name: "Triphala Ext.",
                value: "250mg"
            },
            {
                name: "Isabgol Ext,",
                value: "200mg"
            },
            {
                name: "Sonamukhi Ext.",
                value: "250mg"
            },
            {
                name: "Gulab patra Ext.",
                value: "100mg"
            },
            {
                name: "Sounf Ext.",
                value: "50mg"
            },
            {
                name: "Mulethi Ext.",
                value: "100mg"
            }
        ]
    },
    {
        img: ayurvedaProductImg19,
        heading: "MEMO 9X",
        tableData: [
            {
                name: "Shankhpushpi",
                value: "250mg"
            },
            {
                name: "Bramhi",
                value: "100mg"
            },
            {
                name: "Jyotishmati",
                value: "100mg"
            },
            {
                name: "Manduk parni",
                value: "100mg"
            },
            {
                name: "Ashwagandha",
                value: "200mg"
            },
            {
                name: "Vach",
                value: "100mg"
            },
            {
                name: "Jatamashi",
                value: "200mg"
            }
        ]
    },
    {
        img: ayurvedaProductImg20,
        heading: "ASHTO HAND SANITIZER",
        tableData: [
            {
                name: "Tulsi L. Ext.(Ocimum sanctum)Leaf",
                value: "5.0%"
            },
            {
                name: "Tea Tree (Cajuput)oil (Melaleuca leucadendron)Leaf",
                value: "2.5%"
            },
            {
                name: "Neem L. Ext.(Azadirachta indica)Leaf",
                value: "2.5%"
            },
            {
                name: "Aloe vera pulp (Aloe barbadensis)Leaf s pulp",
                value: "2.5%"
            },
            {
                name: "IPA (Isopropyl alcohol)Chemical",
                value: "70.0%"
            },
            {
                name: "Glycerin(Glycerin)Chemical",
                value: "2.0%"
            },
            {
                name: "Tween-20(Tween-20)Chemical",
                value: "1.0%"
            },
            {
                name: "Carbomer(Carbomer)Chemical",
                value: "0.1%"
            },
            {
                name: "TEA(Triethylamine)Chemical",
                value: "0.1%"
            }
        ]
    },
    {
        img: ayurvedaProductImg21,
        heading: "WOMEN CARE TABLET",
        tableData: [

        ]
    },
    {
        img: ayurvedaProductImg22,
        heading: "DIABA CARE TABLET",
        tableData: [
            {
                name: "Mithi Neem (Murraya Koenigii)",
                value: "250mg"
            },
            {
                name: "Amlki (Emblica officinalis)",
                value: "250mg"
            },
            {
                name: "Mamejava (Enicostemma Littorale)",
                value: "250mg"
            },
            {
                name: "Shilajit (Asphaltum)",
                value: "100mg"
            },
            {
                name: "Arjun (Terminalia Arjuna)",
                value: "150mg"
            }
        ]
    },
    {
        img: ayurvedaProductImg23,
        heading: "KAMA VEDA",
        tableData: [
            {
                name: "Ashwagandha (Withania somnifera,)Root",
                value: "150mg"
            },
            {
                name: "Satavar(Asparagus racemosus) Root",
                value: "150mg"
            },
            {
                name: "Safed Musli(Chlorophytum borivilianum)Root",
                value: "150mg"
            },
            {
                name: "Kouch Beej(Kouch Beej)Fruit",
                value: "120mg"
            },
            {
                name: "Shilajit(Bitumen Mineral)",
                value: "100mg"
            },
            {
                name: "Sidhmakardhwaj(Mineral)",
                value: "10mg"
            },
            {
                name: "Keshar(Crocus sativus)flower",
                value: "10mg"
            },
            {
                name: "Swarn Makshik Bhasm(Mineral)",
                value: "10mg"
            },
            {
                name: "Prawal Pisti (Classical)",
                value: "10mg"
            },
            {
                name: "Mukta Pisti(Classical)",
                value: "05mg"
            },
            {
                name: "AkarKra(Anacyclus pyrethrum)flower",
                value: "25mg"
            },
            {
                name: "Jatifal(Myristica fragrans)fruit",
                value: "10mg"
            }
        ]
    }
]