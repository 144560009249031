import emailjs from '@emailjs/browser';
import { useState, useRef } from "react";
import { formField } from "./data";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function MapAndForm() {
    const [val, setVal] = useState({
        name: "",
        email: "",
        subject: "",
        message: ""
    });


    const toastOfItemAdded = () => (toast.success('Sent successfully!', {
        position: "top-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
    }))

    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();
        emailjs.sendForm('service_ry5h3ch', 'template_ryxurk7', form.current, 'Wx0pQxkyKTxzdbD5U')
            .then((result) => {
                setVal({
                    name: "",
                    email: "",
                    subject: "",
                    message: ""
                });
                toastOfItemAdded();
            })
            .catch((error) => {
                console.log(error.text);
            });
    };

    return (
        <div className="my-[5rem] mx-[2rem] gap-[2rem] flex flex-col justify-center items-center
        sm:m-[5rem]
        lg:flex-row lg:gap-[0rem]
        2xl:max-w-[1360px] 2xl:mx-auto
        ">
            <ToastContainer />
            <div className="w-full h-[38rem]
            lg:w-[50%]
            ">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3462.4206105497674!2d78.40361847455898!3d29.794390630376718!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390965f2de991c89%3A0x3ca06a6e529fd261!2sASHWAN%20HEALTHCARE!5e0!3m2!1sen!2sin!4v1708321175443!5m2!1sen!2sin" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"
                    className="w-full h-full  rounded-[1rem]"
                ></iframe>
            </div>
            <form
                ref={form} onSubmit={sendEmail}
                className="flex flex-col gap-[1rem] shadow-lg w-full h-[31rem] justify-center items-center rounded-[1rem] 
            lg:w-[50%]
            ">
                {formField.map((ele, i) => (
                    <div key={i} className="w-full px-[2rem] sm:px-[4rem] lg:px-[0rem] lg:w-fit">
                        {ele.type === "input" && (
                            <input
                                placeholder={ele.placeholder}
                                name={ele.name}
                                value={val[ele.name]}
                                onChange={(e) => setVal({ ...val, [ele.name]: e.target.value })}
                                className="border-[.12rem] outline-none border-[#6CAAA8] w-full px-[1.2rem] rounded-[2rem] h-[3.2rem] lg:w-[25rem]"
                            />
                        )}
                        {ele.type === "textarea" && (
                            <textarea
                                placeholder={ele.placeholder}
                                name={ele.name}
                                value={val[ele.name]}
                                onChange={(e) => setVal({ [ele.name]: e.target.value })}
                                className="border-[.12rem] outline-none border-[#6CAAA8] w-full p-[.7rem] rounded-[.5rem] h-[10rem] lg:w-[25rem]"
                            />
                        )}
                        {ele.type === "button" && (
                            <button
                                type="submit"
                                className="border-[.1rem] text-[1.05rem] text-white border-[#6CAAA8] bg-[#6CAAA8] rounded-[3rem] py-[.7rem] w-full hover:text-[#6CAAA8] hover:bg-white lg:w-[25rem]"
                            >
                                {ele.placeholder}
                            </button>
                        )}
                    </div>
                ))}
            </form>
        </div>
    );
}

export default MapAndForm;
