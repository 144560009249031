import manufacturingExcellenceImg1 from "./images/manufacturingExcellenceImg1.jpg"
import manufacturingExcellenceImg2 from "./images/manufacturingExcellenceImg2.png"
import manufacturingExcellenceImg3 from "./images/manufacturingExcellenceImg3.jpg"
import manufacturingExcellenceImg4 from "./images/manufacturingExcellenceImg4.jpg"
import manufacturingExcellenceImg5 from "./images/manufacturingExcellenceImg5.jpg"


export const manufacturingExcellenceData = [
    {
        img: manufacturingExcellenceImg1,
        heading: "Herb Sourcing",
        content: "We source herbs and ingredients for our Ayurvedic products from reputable suppliers who adhere to stringent quality standards, ensuring the finest raw materials."
    },
    {
        img: manufacturingExcellenceImg2,
        heading: "Manufacturing Process",
        content: "Our manufacturing process is characterized by precision and efficiency. We combine traditional Ayurvedic techniques with modern technology to create high-quality products."
    },
    {
        img: manufacturingExcellenceImg3,
        heading: "Quality Control",
        content: "Quality control is at the heart of our operations. We employ advanced testing methods to verify the purity, potency, and safety of our Ayurvedic formulations."
    },
    {
        img: manufacturingExcellenceImg4,
        heading: "Regulatory Compliance",
        content: "We strictly adhere to regulatory requirements, ensuring that our manufacturing practices meet industry standards and guidelines. Our products are compliant and safe."
    },
    {
        img: manufacturingExcellenceImg5,
        heading: "Research and Innovation",
        content: "We invest in research and innovation to continuously improve our manufacturing processes and create new, effective Ayurvedic products for our customers."
    }
]