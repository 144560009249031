import { biographyData } from "./data"
import { Link } from "react-router-dom"

function Biography() {
    return (
        <div className="font-serif bg-[#6CAAA814] p-[2rem]
        sm:p-[5rem]
        ">
            {biographyData.map((ele, i) => (
                <div
                    key={i}
                    className="flex flex-col justify-between items-center gap-[2rem]
                    md:flex-row md:gap-[0rem]
                    2xl:max-w-[1360px] 2xl:mx-auto
                    "
                >
                    <div className="w-full h-[20rem] md:w-[45%] md:h-[25rem]">
                        <img src={ele.founderImage} alt="error" className="w-full h-full" />
                    </div>
                    <div className="w-full flex flex-col gap-[1rem]
                    md:w-[45%]
                    ">
                        <div className="text-[1.1rem]">
                            <p className="font-bold text-[2rem] mb-[.5rem]">{ele.founderName}</p>
                            <p>{ele.designation}</p>
                            <p>{ele.aboutFounder}</p>
                        </div>
                        <div>
                            <div className="text-[1.1rem]">
                                <p className="font-semibold text-[1.5rem] mb-[.5rem]">{ele.heading}</p>
                                <p>{ele.content}</p>
                            </div>
                            <div className="flex gap-[.5rem]">
                                {ele.icon && Array.isArray(ele.icon) ? (
                                    ele.icon.map((icon, index) => (
                                        <div key={index} className="mt-[.5rem]">
                                            <Link><span>{icon.iconName}</span></Link>
                                        </div>
                                    ))
                                ) : null}
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    )
}

export default Biography
