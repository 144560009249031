import classicalMedicineImg1 from "./images/classicalMedicineImg1.webp"
import classicalMedicineImg2 from "./images/classicalMedicineImg2.webp"
import classicalMedicineImg3 from "./images/classicalMedicineImg3.webp"
import classicalMedicineImg4 from "./images/classicalMedicineImg4.webp"
import classicalMedicineImg5 from "./images/classicalMedicineImg5.webp"

export const productsAndTableData = [
    {
        img: classicalMedicineImg1,
        heading: "KAMDEV CHURNA",
        tableData: [

        ]
    },
    {
        img: classicalMedicineImg2,
        heading: "AVIPATTIKAR CHURNA",
        tableData: [

        ]
    },
    {
        img: classicalMedicineImg3,
        heading: "TALISADI CHURNA",
        tableData: [

        ]
    },
    {
        img: classicalMedicineImg4,
        heading: "PANCHSAKAR CHURNA",
        tableData: [

        ]
    },
    {
        img: classicalMedicineImg5,
        heading: "SITOPLADI CHURNA",
        tableData: [

        ]
    }
]