import growingImg from "./growingImg.webp"

export const growingData = [
    {
        img: growingImg,
        heading: "Fastest Growing Pharmaceutical Company",
        content: [
            "Ashwan Healthcare is a rapidly growing pharmaceutical company.",
            "Our manufacturing facility is equipped with state-of-the-art technology.",
            "We have a team of highly qualified professionals guiding the manufacturing process.",
            {
                "All our products are rigorously tested and validated using standardized procedures, including:": [
                    " Physico - Chemical Analysis",
                    "Heavy Metals Testing",
                    "Pure Herbs",
                ]
            },
            "Mainly, our formulations use herbal extracts in a 1:10 ratio, or as required for specific cases.",
            "All our products go through evaluation by our Quality Management System, certified to ISO 9001:2015 standards.",
            "We adhere to WHO-GMP norms to ensure world-class quality in both our products and packaging.",
        ]
    }
]