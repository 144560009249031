import qualityControlImg1 from "./images/qualityControlImg1.jpg"
import qualityControlImg2 from "./images/qualityControlImg2.jpg"
import qualityControlImg3 from "./images/qualityControlImg3.jpg"
import qualityControlImg4 from "./images/qualityControlImg4.jpg"
import qualityControlImg5 from "./images/qualityControlImg5.jpg"


export const qualityControlData = [
    {
        img: qualityControlImg1,
        heading: "Herb Identification",
        content: "We start by accurately identifying the herbs and ingredients used in our Ayurvedic products. This critical step ensures that we use the correct herbs for each formulation."
    },
    {
        img: qualityControlImg2,
        heading: "Purity and Potency",
        content: "Our quality control measures include testing for purity and potency. We verify that the herbs used are free from contaminants and contain the expected levels of active compounds."
    },
    {
        img: qualityControlImg3,
        heading: "Manufacturing Standards",
        content: "Our manufacturing process strictly follows Ayurvedic standards and GMP (Good Manufacturing Practices). We maintain a clean and controlled environment for product consistency."
    },
    {
        img: qualityControlImg4,
        heading: "Quality Assurance",
        content: "Throughout production, our quality assurance team monitors every stage to ensure adherence to quality standards. This includes checks for consistency and purity."
    },
    {
        img: qualityControlImg5,
        heading: "Testing and Certification",
        content: "Our Ayurvedic products undergo comprehensive testing for safety and efficacy. Once approved, they receive the necessary certifications and are ready for distribution."
    }
]