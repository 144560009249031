import { manufacturingExcellenceData } from "./data";
import { Fade, Flip, Zoom } from "react-reveal"

function ManufacturingExcellence() {
    return (
        <div className="flex flex-col gap-[2rem] my-[8rem] mx-[2rem]
        md:mx-[4rem]
        lg:m-[8rem]
        2xl:max-w-[1360px] 2xl:mx-auto
        ">
            <div className="font-bold text-[2rem] font-serif text-center break-all">
                <p>Manufacturing Excellence</p>
            </div>
            <div className="flex flex-wrap gap-[4rem] justify-center">
                {
                    manufacturingExcellenceData.map((ele, i) => {
                        return <div
                            key={i}
                            className="flex shadow-lg flex-col gap-[.5rem] p-[1.5rem] w-[20rem] font-serif"
                        >
                            <Zoom>
                                <img
                                    src={ele.img}
                                    alt="error"
                                    className="h-[10rem]"
                                />
                            </Zoom>
                            <Flip top><p className="font-bold text-[1.3rem] text-[#6CAAA8]">{ele.heading}</p></Flip>
                            <Fade bottom><p className="text-[1.05rem] text-slate-400">{ele.content}</p></Fade>
                        </div>
                    })
                }
            </div>
        </div>
    )
}

export default ManufacturingExcellence;
