export const productionCapacityData = [
    {
        itemsName: "Tablets",
        capacity: "1500"
    },
    {
        itemsName: "Capsules",
        capacity: "1500"
    },
    {
        itemsName: "Ointment",
        capacity: "50"
    },
    {
        itemsName: "Protein Powder",
        capacity: "15"
    },
    {
        itemsName: "Syrup",
        capacity: "50"
    },
    {
        itemsName: "Lotion/ Shampoo/ Oil",
        capacity: "1500"
    },
    {
        itemsName: "Churna",
        capacity: "1500"
    },
    {
        itemsName: "Avleha",
        capacity: "1000"
    }
]