import { productCardData } from "./data"
import { Link } from "react-router-dom"
import { Zoom } from "react-reveal"

function ProductCard() {
    return (
        <div className="m-[5rem] 2xl:max-w-[1360px] 2xl:mx-auto">
            <div className="flex flex-wrap justify-around gap-[3rem]
            xl:gap-[0rem]
            ">
                {
                    productCardData.map((ele, i) => {
                        return <div
                            key={i}
                            className="text-center shadow-lg p-[2rem] rounded-[1.2rem] font-serif text-[1.2rem] font-semibold w-[20rem]
                            xl:w-fit
                            "
                        >
                            <Zoom><img src={ele.img} alt="error" /></Zoom>
                            <Link to={ele.link}><p className="mt-[.5rem]">{ele.heading}</p></Link>
                        </div>
                    })
                }
            </div>
        </div>
    )
}

export default ProductCard
