import AboutAyurveda from './aboutAyurveda/AboutAyurveda'
import ClassicalAyurveda from './classicalAyurveda/ClassicalAyurveda';
import Growing from './growing/Growing'
import ProductionCapacity from './productionCapacity/ProductionCapacity';
import Banner from "../../../components/banner/Banner"

function Ayurveda() {
  return (
    <div>
      <Banner heading={"Ayurveda"} />
      <AboutAyurveda />
      <Growing />
      <ProductionCapacity />
      <ClassicalAyurveda />
    </div>
  )
}

export default Ayurveda;
