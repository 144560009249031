export const contactData = [
    {
        heading: "Contact Details",
        content: [
            "☎ 0120-24956666",
            "📞 9891111645/9891111641",
            "📧 info@ashwanhealthcare.com",
            "🌐 www.ashwanhealthcare.com"
        ],
    },
    {
        heading: "Factory Address",
        content: "Address: E2B, Sector-1, III GROWTH CENTER SIGAADI, SIDCUL KOTDWAR, UK-246149",
        map: `<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3462.4206105497674!2d78.40361847455898!3d29.794390630376718!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390965f2de991c89%3A0x3ca06a6e529fd261!2sASHWAN%20HEALTHCARE!5e0!3m2!1sen!2sin!4v1708321175443!5m2!1sen!2sin" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>`
    },
    {
        heading: "Regd. Address",
        content: "Address: B7/28, SITE C UPSIDC INDUSTRIAL AREA, SURAJPUR, GR NOIDA, G.B. NAGAR UP-201306",
        map: `<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3506.1047703602003!2d77.5184136752214!3d28.506495389793578!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ceb57adac1577%3A0x20c60c539ffa972!2sShri%20Ram%20Industries!5e0!3m2!1sen!2sin!4v1698814715424!5m2!1sen!2sin" style="border:0; allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>`
    },
    {
        heading: "Corporatge Office Address",
        content: "Address: 607, PRABHAT CENTER, ANNEX SEC1A CBD BELAPUR, NAVI MUMBAI-400614",
        map: `<iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15087.53695740791!2d73.041012!3d19.024822!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c24cb23ec507%3A0x659d8be3f98d37eb!2sPrabhat%20Center%2C%20Sakaram%20Patil%20Marg%2C%20Sector%201A%2C%20CBD%20Belapur%2C%20Navi%20Mumbai%2C%20Maharashtra%20400614!5e0!3m2!1sen!2sin!4v1708322077536!5m2!1sen!2sin" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>`
    }
]