import aboutAyurvedaImg from "./images/AboutAyurvedaImg1.jpg"

export const aboutAyurvedaData = [
    {
        img: aboutAyurvedaImg,
        heading: "Ayurveda",
        content: `Ayurveda is an ancient system of medicine that has been practiced in India for thousands of years. It is a holistic approach to health and wellness that focuses on balancing the body, mind, and spirit to promote overall well-being.

        It's essential to consult with a qualified Ayurvedic practitioner or healthcare professional before attempting any Ayurvedic treatments. Some herbs and practices may interact with existing medical conditions or medications. Ayurveda offers a comprehensive and time-tested approach to health and wellness, aiming to promote harmony within the body and between the individual and their environment. While it has its roots in ancient traditions, its principles continue to be relevant in the pursuit of holistic well-being in today's world.`
    }
]