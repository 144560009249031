import Banner from "../../components/banner/Banner";
import ContactDetails from "./contactDetails/ContactDetails";
import CountrySlider from "./countrySlider/CountrySlider";
import MapAndForm from "./mapAndForm/MapAndForm";

function Contact() {
  return (
    <div>
      <Banner heading={"Contect Us"} />
      <MapAndForm />
      <CountrySlider />
      <ContactDetails />
    </div>
  )
}

export default Contact;
