import { whyPureAyurvedaData } from "./data"
import { Fade, Zoom, Flip } from "react-reveal"

function WhyPureAyurveda() {
    return (
        <div className="py-[5rem] px-[2rem] flex gap-[3rem] flex-col items-center justify-center bg-[#6CAAA814]
        sm:p-[5rem]
        ">
            <div className="font-serif flex flex-col items-center justify-center">
                <Zoom><img src="https://kamleshyadav.com/html/pure-ayurveda/html/assets/images/herbal.svg" alt="error" /></Zoom>
                <Flip top><p className="font-bold text-center text-[2rem]">Why Pure Ayurveda</p></Flip>
                <Flip top><p className="text-[#6CAAA8] font-semibold text-[1.2rem]">Best For You</p></Flip>
            </div>
            <div className="flex flex-col items-center gap-[5rem] 
            lg:flex-row
            ">
                <ul className="list-none flex flex-col gap-[2rem] text-[1rem] text-[#6CAAA8] hidden
                lg:flex
                ">
                    {
                        whyPureAyurvedaData.map((ele, i) => {
                            return <Fade left><li key={i}>{ele}</li></Fade>
                        })
                    }
                </ul>
                <div className="w-[15rem] flex justify-center rounded-[50%] border-dashed h-[15rem] border-[#6CAAA8] border-[.12rem]
                lg:w-[25rem] lg:h-[25rem]
                ">
                    <Zoom><img src="https://kamleshyadav.com/html/pure-ayurveda/html/assets/images/herbal.svg" alt='error' className="w-[5rem] lg:w-[10rem]" /></Zoom>
                </div>
                <ul className="list-none flex flex-wrap flex-row gap-[2rem] text-[1rem] text-[#6CAAA8]
                lg:flex-col
                ">
                    {
                        whyPureAyurvedaData.map((ele, i) => {
                            return <Fade right><li key={i}>{ele}</li></Fade>
                        })
                    }
                </ul>
            </div>
        </div>
    )
}

export default WhyPureAyurveda;
