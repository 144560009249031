import { storyData } from "./data"
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function OurStory() {

    let settings = {
        arrows: false,
        dots: false,
        infinite: true,
        speed: 1000,
        autoplay: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        variableWidth: false,
        pauseOnHover: true
    };

    return (
        <div className="2xl:max-w-[1360px] 2xl:mx-auto">
            {
                storyData.map((ele, i) => {
                    return <div
                        key={i}
                        className="mx-[2rem] my-[6rem] flex flex-col items-center gap-[2rem]
                        sm:mx-[5rem] my-[6rem]
                        lg:flex-row
                        "
                    >
                        <Slider {...settings} className="w-full lg:w-[50%]">
                            {
                                ele.img
                                &&
                                Array.isArray(ele.img)
                                &&
                                ele.img.map((ele, i) => {
                                    return <img
                                        src={ele}
                                        key={i}
                                        className="h-[26rem]"
                                    />
                                })
                            }
                        </Slider>
                        <div className="w-full font-serif">
                            <img
                                src={ele.icon}
                                alt="error"
                                className="mb-[.5rem] hidden lg:inline"
                            />
                            <p className="font-bold text-[1.7rem]">{ele.heading}</p>
                            <p className="text-[#6CAAA8] text-[1.3rem] font-semibold">{ele.subHeading}</p>
                            <p className="font-serif text-slate-400 text-[1.1rem] mt-[.5rem]">{ele.content}</p>
                        </div>
                    </div>
                })
            }
        </div>
    )
}

export default OurStory;