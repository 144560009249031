import { useState } from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { Link } from 'react-router-dom';
import { menuList } from './data';
import ashwanhealthcareLogo from "./ashwanhealthcareLogo.webp"

function DrawerNavbar() {

    const [drop, setDrop] = useState(Array(menuList.length).fill(false));

    const handleClick = (index) => {
        const openChild = [...drop];
        openChild[index] = !openChild[index];
        setDrop(openChild);
    }

    const [state, setState] = useState({
        left: false,
    });

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };

    const list = (anchor) => (
        <Box
            role="presentation"
            onKeyDown={toggleDrawer(anchor, false)}
            className='bg-[#6CAAA814] h-full'
        >
            <List>
                {menuList.map((ele, index) => (
                    <>
                        <Link to={ele.path}
                            onClick={!ele.arr && toggleDrawer(anchor, false)}
                        >
                            <ListItem key={index} disablePadding
                                onClick={() => ele.arr && handleClick(index)}
                            >
                                <ListItemButton sx={{ width: "280px" }}>
                                    {
                                        ele.arr
                                            ?
                                            <>
                                                <ListItemText primary={ele.name} className='text-[#6CAAA8]' />
                                                {drop[index] ? <ExpandMore className='text-[#6CAAA8]' /> : <ExpandLess className='text-[#6CAAA8]' />}
                                            </>
                                            :
                                            <ListItemText primary={ele.name} className='text-[#6CAAA8]' />
                                    }
                                </ListItemButton>
                            </ListItem>
                        </Link>
                        {
                            ele.arr &&
                            <Collapse in={drop[index]} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    {
                                        ele.arr && Array.isArray(ele.arr) ? ele.arr.map((ele, i) => (
                                            <Link to={ele.path}
                                                onClick={toggleDrawer(anchor, false)}
                                            >
                                                <ListItemButton key={i}>
                                                    <ListItemText
                                                        primary={ele.name}
                                                        sx={{ paddingLeft: "2rem", color: "#6CAAA8" }}
                                                    />
                                                </ListItemButton>
                                            </Link>
                                        ))
                                            :
                                            null
                                    }
                                </List>
                            </Collapse>
                        }
                    </>
                ))}
            </List>
        </Box>
    )

    return (
        <div className='h-[6rem] sticky top-0 z-10 bg-[#6CAAA8] flex items-center justify-between px-[3rem]
        md:hidden
        '>
            <div className="w-[150px]">
                <img src={ashwanhealthcareLogo} />
            </div>
            <div>
                {['right'].map((anchor) => (
                    <div key={anchor}>
                        <MenuIcon onClick={toggleDrawer(anchor, true)} sx={{ fontSize: "2rem", color: "black" }} />
                        <Drawer
                            anchor={anchor}
                            open={state[anchor]}
                            onClose={toggleDrawer(anchor, false)}
                        >
                            {list(anchor)}
                        </Drawer>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default DrawerNavbar;