import { ourBusinessData } from "./data";
import Dropdown from "../../components/dropdown/Dropdown";

function OurBusiness() {
    return (
        <div>
            <Dropdown name={"Our Business"} data={ourBusinessData} />
        </div>
    );
}

export default OurBusiness;