import awardsAndRecognitionImg1 from "./images/awardsAndRecognitionImg1.webp"
import awardsAndRecognitionImg2 from "./images/awardsAndRecognitionImg2.webp"
import awardsAndRecognitionImg4 from "./images/awardsAndRecognitionImg4.webp"
import awardsAndRecognitionImg5 from "./images/awardsAndRecognitionImg5.webp"
// import awardsAndRecognitionImg6 from "./images/awardsAndRecognitionImg6.webp"
import awardsAndRecognitionImg7 from "./images/awardsAndRecognitionImg7.webp"
import awardsAndRecognitionImg8 from "./images/awardsAndRecognitionImg8.webp"
import awardsAndRecognitionImg9 from "./images/awardsAndRecognitionImg9.webp"
import awardsAndRecognitionImg10 from "./images/awardsAndRecognitionImg10.webp"
import awardsAndRecognitionImg11 from "./images/awardsAndRecognitionImg11.webp"
import awardsAndRecognitionImg13 from "./images/awardsAndRecognitionImg13.webp"
import awardsAndRecognitionImg14 from "./images/awardsAndRecognitionImg14.webp"
import awardsAndRecognitionImg15 from "./images/awardsAndRecognitionImg15.webp"
import awardsAndRecognitionImg16 from "./images/awardsAndRecognitionImg16.webp"
import awardsAndRecognitionImg17 from "./images/awardsAndRecognitionImg17.webp"
import awardsAndRecognitionImg18 from "./images/awardsAndRecognitionImg18.webp"
import awardsAndRecognitionImg19 from "./images/awardsAndRecognitionImg19.webp"
import awardsAndRecognitionImg20 from "./images/awardsAndRecognitionImg20.webp"
import awardsAndRecognitionImg23 from "./images/awardsAndRecognitionImg23.webp"
import awardsAndRecognitionImg24 from "./images/awardsAndRecognitionImg24.webp"
import awardsAndRecognitionImg25 from "./images/awardsAndRecognitionImg25.webp"
import awardsAndRecognitionImg26 from "./images/awardsAndRecognitionImg26.webp"


export const awardsAndRecognitionData = [
    {
        name: awardsAndRecognitionImg1,
        path: "http://localhost:3000/static/media/awardsAndRecognitionImg1.12d03272445cff0ec093.webp"
    },
    {
        name: awardsAndRecognitionImg2,
        path: "http://localhost:3000/static/media/awardsAndRecognitionImg2.467126fc94b6b36b3935.webp"
    },
    {
        name: awardsAndRecognitionImg4,
        path: "http://localhost:3000/static/media/awardsAndRecognitionImg4.641af9d4acba359e795e.webp"
    },
    {
        name: awardsAndRecognitionImg5,
        path: "http://localhost:3000/static/media/awardsAndRecognitionImg5.daa5edca3d90ab492f07.webp"
    },
    {
        name: awardsAndRecognitionImg7,
        path: "http://localhost:3000/static/media/awardsAndRecognitionImg7.9530070101a45372f98d.webp",
    },
    {
        name: awardsAndRecognitionImg8,
        path: "http://localhost:3000/static/media/awardsAndRecognitionImg8.a6386b1f0774e4e5628b.webp",
    },
    {
        name: awardsAndRecognitionImg9,
        path: "http://localhost:3000/static/media/awardsAndRecognitionImg8.a6386b1f0774e4e5628b.webp",
    },
    {
        name: awardsAndRecognitionImg10,
        path: "http://localhost:3000/static/media/awardsAndRecognitionImg10.0da386557bca60ede728.webp",
    },
    {
        name: awardsAndRecognitionImg11,
        path: "http://localhost:3000/static/media/awardsAndRecognitionImg11.6c95b1fe04e3d5c5f967.webp",
    },
    {
        name: awardsAndRecognitionImg13,
        path: "http://localhost:3000/static/media/awardsAndRecognitionImg13.9e5e08660ebbbb1f96e0.webp",
    },
    {
        name: awardsAndRecognitionImg14,
        path: "http://localhost:3000/static/media/awardsAndRecognitionImg14.5420e76e5ee7bac152e8.webp",
    },
    {
        name: awardsAndRecognitionImg15,
        path: "http://localhost:3000/static/media/awardsAndRecognitionImg15.714b1f8735d440259235.webp",
    },
    {
        name: awardsAndRecognitionImg16,
        path: "http://localhost:3000/static/media/awardsAndRecognitionImg16.7b34336744fb24f01399.webp",
    },
    {
        name: awardsAndRecognitionImg17,
        path: "http://localhost:3000/static/media/awardsAndRecognitionImg17.e6828cf0ed0adf4abe36.webp",
    },
    {
        name: awardsAndRecognitionImg18,
        path: "http://localhost:3000/static/media/awardsAndRecognitionImg18.15d66695288a9d78963a.webp",
    },
    {
        name: awardsAndRecognitionImg19,
        path: "http://localhost:3000/static/media/awardsAndRecognitionImg19.d3493dc85622a6566bbe.webp",
    },
    {
        name: awardsAndRecognitionImg20,
        path: "http://localhost:3000/static/media/awardsAndRecognitionImg20.c9dd648791382b934b06.webp",
    },
    {
        name: awardsAndRecognitionImg23,
        path: "http://localhost:3000/static/media/awardsAndRecognitionImg23.e28e2a1e619e9f28c04c.webp",
    },
    {
        name: awardsAndRecognitionImg24,
        path: "http://localhost:3000/static/media/awardsAndRecognitionImg24.e8c1c79092a032cc8f0b.webp",
    },
    {
        name: awardsAndRecognitionImg25,
        path: "http://localhost:3000/static/media/awardsAndRecognitionImg25.ff47ee51e6bcd5784e67.webp",
    },
    {
        name: awardsAndRecognitionImg26,
        path: "http://localhost:3000/static/media/awardsAndRecognitionImg26.29d9886030c2a5905482.webp"
    }
]