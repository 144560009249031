import FacebookIcon from '@mui/icons-material/Facebook';
import XIcon from '@mui/icons-material/X';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import YouTubeIcon from '@mui/icons-material/YouTube';
import biography from "./biography.webp"

export const biographyData = [
    {
        founderImage: biography,
        founderName: "Dr. D.K. Chaturvedi",
        designation: "Director & Founder",
        aboutFounder: "Members of Pharmaceutical export promotion council of india",
        heading: "Biography",
        content: "We dedicate ourselves to humanity's quest for longer, healthier, happier lives through innovahive pharmaceutical products.",
        icon: [
            {
                iconName: < FacebookIcon sx={{ color: "#6CAAA8" }} />,
                link: "https://facebook.com",
            },
            {
                iconName: <XIcon sx={{ color: "#6CAAA8" }} />,
                link: "https://twitter.com",
            },
            {
                iconName: <LinkedInIcon sx={{ color: "#6CAAA8" }} />,
                link: "https://linkedin.com",
            },
            {
                iconName: <YouTubeIcon sx={{ color: "#6CAAA8" }} />,
                link: "https://youtube.com",
            }
        ]
    }
]