import { productionCapacityData } from "./data";
import CountUp, { useCountUp } from 'react-countup';
import ProductionCapacityImg1 from "./productionCapacityImg1.webp"

function ProductionCapacity() {

    useCountUp({
        ref: 'counter',
        end: 1234567,
        enableScrollSpy: true,
        scrollSpyDelay: 1000,
        duration: 10
    });

    return (
        <div
            className="flex flex-col text-center text-[#6CAAA8] font-serif gap-[2rem]"
        >
            <div>
                <p className="font-bold text-[2rem]">Production Capacity</p>
            </div>
            <div className="relative">
                <img
                    src={ProductionCapacityImg1}
                    alt="error"
                    className="opacity-25 w-full h-[150rem]
                    md:h-[55rem]
                    lg:h-[43rem]
                    xl:h-[32rem]
                    "
                />
                <div className="flex absolute inset-0 flex-wrap p-[5rem] justify-center gap-[2rem]
                md:justify-between
                2xl:max-w-[1360px] 2xl:mx-auto
                ">
                    {
                        productionCapacityData.map((ele, i) => {
                            return <div
                                key={i}
                                className="w-[25rem] bg-white font-serif h-[15rem] flex flex-col justify-center items-center gap-[.5rem] rounded-lg shadow-2xl
                                md:w-[15rem] md:h-[10rem]
                                "
                            >
                                <span
                                    className="text-[2rem] text-black"
                                >
                                    <CountUp
                                        start={0}
                                        end={ele.capacity}
                                        enableScrollSpy
                                    />
                                    K
                                </span>
                                <p className="font-bold text-[1.2rem] text-[#6CAAA8]">{ele.itemsName}</p>
                            </div>
                        })
                    }
                </div>
            </div>
        </div>
    )
}

export default ProductionCapacity;
