import BannerImg from "./bannerImg.jpg";

function Banner({ heading }) {
    return (
        <div className="relative">
            <div className="absolute inset-0 bg-gradient-to-tr from-black-56 to-black-56 opacity-70"></div>
            <img
                src={BannerImg}
                alt="Banner"
                className="h-[15rem] w-full object-cover"
                style={{ filter: "brightness(50%)" }}
            />
            <div className="absolute inset-0 flex items-center justify-center">
                <p className="font-bold text-3xl text-white">{heading}</p>
            </div>
        </div>
    );
}

export default Banner;
