import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { countrySliderData } from "./data";
import { useState, useEffect } from "react";

function CountrySlider() {

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    let slidesToShow = 3;
    let flagImgWidth = "350px";

    if (windowWidth <= 1235) {
        slidesToShow = 2;
        flagImgWidth = "450px";
    }
    if (windowWidth <= 1080) {
        flagImgWidth = "400px";
    }
    if (windowWidth <= 990) {
        flagImgWidth = "350px";
    }
    if (windowWidth <= 890) {
        flagImgWidth = "300px";
    }
    if (windowWidth <= 780) {
        flagImgWidth = "250px";
    }
    if (windowWidth <= 680) {
        slidesToShow = 1;
        flagImgWidth = "500px";
    }

    let settings = {
        arrows: false,
        dots: false,
        infinite: true,
        speed: 1000,
        autoplay: true,
        slidesToShow: slidesToShow,
        slidesToScroll: 1,
        variableWidth: false,
        pauseOnHover: true
    };

    return (
        <div className="mx-[2rem] my-[8rem] flex flex-col gap-[2rem]
        md:mx-[5rem]
        2xl:max-w-[1360px] 2xl:mx-auto
        ">
            <div className="font-bold text-[1.5rem] font-serif text-[#6CAAA8] text-center mx-[2rem]
            sm:mx-[3rem]
            md:mx-[5rem]
            lg:mx-[10rem]
            xl:mx-[15rem]
            ">
                <p>Our Products Export in Russia, Nepal, Afghanistan, Cambodia, Yaman</p>
            </div>
            <Slider {...settings} className="flex justify-around">
                {
                    countrySliderData.map((ele, i) => {
                        return <div
                            key={i}
                        >
                            <img src={ele} alt="error" style={{ width: flagImgWidth, height: '250px' }} />
                        </div>
                    })
                }
            </Slider>
        </div>
    )
}

export default CountrySlider;
