import OurBusiness from "../../pages/ourBusiness/OurBusiness";
import Products from "../../pages/products/Products";

export const headerData = [
    {
        name: "Home",
        path: "/"
    },
    {
        name: "About",
        path: "/about"
    },
    {
        name: <OurBusiness />,

    },
    {
        name: <Products />,
    },
    {
        name: "Operations",
        path: "/operations"
    },
    {
        name: "Contact Us",
        path: "/contactUs"
    }
]