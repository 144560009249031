import React from 'react'
import ProductsAndTable from './productsAndTable/ProductsAndTable';

function Nutraceutical() {
    return (
        <div>
            <ProductsAndTable />
        </div>
    )
}

export default Nutraceutical;
