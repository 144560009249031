// export const mapAndFormData = [
//     {}
// ]

export const formField = [
    {
        placeholder: "Enter your name",
        type: "input",
        name:"name"
    },
    {
        placeholder: "Enter your email",
        type: "input",
        name:"email"
    },
    {
        placeholder: "Enter your subject",
        type: "input",
        name:"subject"
    },
    {
        placeholder: "Message here",
        type: "textarea",
        name:"message"
    },
    {
        placeholder: "Submit",
        type: "button"
    }
]