import MilitaryTechOutlinedIcon from '@mui/icons-material/MilitaryTechOutlined';
import MedicationOutlinedIcon from '@mui/icons-material/MedicationOutlined';
import LocalAtmOutlinedIcon from '@mui/icons-material/LocalAtmOutlined';
import SupportAgentOutlinedIcon from '@mui/icons-material/SupportAgentOutlined';

export const supportData = [
    {
        img: <MilitaryTechOutlinedIcon sx={{
            fontSize: "3.5rem",
            color: "#6CAAA8"
        }} />,
        heading: "Award Winning",
        content: "Empowering Health, Enriching Lives, One Pill at a Time."
    },
    {
        img: <MedicationOutlinedIcon sx={{
            fontSize: "3.5rem",
            color: "#6CAAA8"
        }} />,
        heading: "Expert Doctors",
        content: `Fully Devoted for Pharmaceutical Excellence, Exceptional Care: "Our Commitment to Your Well- Being".`
    },
    {
        img: <LocalAtmOutlinedIcon sx={{
            fontSize: "3.5rem",
            color: "#6CAAA8"
        }} />,
        heading: "Fair Prices",
        content: "Providing fair prices for a Human Well Being."
    },
    {
        img: <SupportAgentOutlinedIcon sx={{
            fontSize: "3.5rem",
            color: "#6CAAA8"
        }} />,
        heading: "24/7 Support",
        content: "Reliable 24/7 Support for Your Pharmaceutical Needs"
    }
]