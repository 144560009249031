import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

const number = "919891111645"

export const footerData = [
    {
        name: [
            {
                pageName: "Home",
                link: "/",
            },
            {
                pageName: "About",
                link: "/about",

            },
            {
                pageName: "Operations",
                link: "/operations",
            },
            {
                pageName: "Contact Us",
                link: "/contactUs",
            }
        ],
        type: "INFORMATION",
    },
    {
        name: [
            {
                pageName: "Patent Medicine",
                link: "/products/patentMedicine",
            },
            {
                pageName: "Herbs Medicine",
                link: "/products/pureHerbs",
            },
            {
                pageName: "Classical Medicine",
                link: "/products/classicalMedicine",
            },
            {
                pageName: "Neutraceuticals",
                link: "/ourBusiness/Nutraceutical",
            }
        ],
        type: "PRODUCTS",
    },
    {
        name: "Any questions? Let us know in E2B, Sector-1, III GROWTH CENTER SIGAADI, SIDCUL KOTDWAR, UK-(246149) or call us on 0120-2495666 / 9891111645",
        type: "GET IN TOUCH",
    },
    {
        name: [
            {
                icon: <FacebookIcon sx={{ color: "#cbd5e1" }} />,
                link: "https://www.facebook.com/ashwanhealthcare.kotdwar/",
            },
            {
                icon: <InstagramIcon sx={{ color: "#cbd5e1" }} />,
                link: "https://www.instagram.com/",
            },
            {
                icon: <LinkedInIcon sx={{ color: "#cbd5e1" }} />,
                link: "https://linkedin.com/",
            },
            {
                icon: <WhatsAppIcon sx={{ color: "#cbd5e1" }} />,
                link: `https://api.whatsapp.com/send?phone=${number}`,
            }
        ],
        type: "icon",
    },
    {
        text: "2024 © Ashwan Health Care, All Rights Reserved",
        type: "Copyright"
    }
]
