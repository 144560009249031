export const counterData = [
    {
        name: "Years Experience",
        count:"9"
    },
    {
        name: "Awards",
        count:"100"
    },
    {
        name: "Completed Cases",
        count:"1200"
    },
    {
        name: "Happy Clients",
        count:"1000"
    },
]