export const productsData = [
    {
        link: "/products/patentMedicine",
        pageName: "Patent Medicine"
    },
    {
        link: "/products/classicalMedicine",
        pageName: "Classical Medicine"
    },
    {
        link: "/products/pureHerbs",
        pageName: "Pure Herbs"
    }
]