import ProductsAndTable from './productsAndTable/ProductsAndTable'

function PatentMedicine() {
    return (
        <div>
            <ProductsAndTable />
        </div>
    )
}

export default PatentMedicine;
