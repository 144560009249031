import productCardImg1 from "./images/productCardImg1.jpg"
import productCardImg2 from "./images/productCardImg2.jpg"
import productCardImg3 from "./images/productCardImg3.jpg"

export const productCardData = [
    {
        img: productCardImg1,
        heading: "Patent Medicine",
        link: "/products/patentMedicine"
    },
    {
        img: productCardImg2,
        heading: "Classical Medicine",
        link: "/products/classicalMedicine"
    },
    {
        img: productCardImg3,
        heading: "Pure Herbs",
        link: "/products/pureHerbs"
    }
]