import ExpertsAndTechnologies from "./expertsAndTechnologies/ExpertsAndTechnologies";
import HomeBanner from "./homeBanner/HomeBanner";
import ProductCard from "./productCard/ProductCard";
import ProductNorms from "./productNorms/ProductNorms";
import Support from "./support/Support";
import WhyPureAyurveda from "./whyPureAyurveda/WhyPureAyurveda";

function Home() {
    return (
        <div>
            <HomeBanner />
            <ProductCard />
            <WhyPureAyurveda />
            <ExpertsAndTechnologies />
            <Support />
            <ProductNorms />
        </div>
    )
}

export default Home;
