import ProductsAndTable from './productsAndTable/productsAndTable'

function ClassicalMedicine() {
    return (
        <div>
            <ProductsAndTable />
        </div>
    )
}

export default ClassicalMedicine;
