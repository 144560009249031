import { Link } from "react-router-dom";
import { useState } from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import makeStyles from "@material-ui/styles/makeStyles";

const useStyles = makeStyles({
    popOverRoot: {
        pointerEvents: "none"
    }
});

function Dropdown({ name, data }) {
    let currentlyHovering = false;
    const styles = useStyles();

    const [anchorEl, setAnchorEl] = useState(null);

    function handleClick(event) {
        if (anchorEl !== event.currentTarget) {
            setAnchorEl(event.currentTarget);
        }
    }

    function handleHover() {
        currentlyHovering = true;
    }

    function handleClose() {
        setAnchorEl(null);
    }

    function handleCloseHover() {
        currentlyHovering = false;
        setTimeout(() => {
            if (!currentlyHovering) {
                handleClose();
            }
        }, 0);
    }

    return (
        <div>
            <p
                aria-owns={anchorEl ? "simple-menu" : undefined}
                aria-haspopup="true"
                onClick={handleClick}
                onMouseOver={handleClick}
                onMouseLeave={handleCloseHover}
            >
                {name}
            </p>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                MenuListProps={{
                    onMouseEnter: handleHover,
                    onMouseLeave: handleCloseHover,
                    style: { pointerEvents: "auto" }
                }}
                getContentAnchorEl={null}
                anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
                PopoverClasses={{
                    root: styles.popOverRoot
                }}
                className="mt-[.1rem]"
            >
                {
                    data.map((ele, index) => {
                        return <Link to={ele.link}>
                            <MenuItem onClick={handleClose} key={index}
                            >
                                {ele.pageName}
                            </MenuItem>
                        </Link>
                    })
                }
            </Menu>
        </div>
    );
}

export default Dropdown;
