import cer1 from "./images/cer1.webp"
import cer2 from "./images/cer2.webp"
import cer3 from "./images/cer3.webp"

export const certificateData = [
    {
        name: cer1,
        path: "http://localhost:3000/static/media/cer1.cc8aebea82765bf0b85b.webp"
    },
    {
        name: cer2,
        path: "http://localhost:3000/static/media/cer2.ab560de3805956287edd.webp"
    },
    {
        name: cer3,
        path: "http://localhost:3000/static/media/cer3.03424fb7ea4baec0d327.webp"
    }
]