import ProductsAndTable from "./productsAndTable/ProductsAndTable"

function PureHerbs() {
    return (
        <div>
            <ProductsAndTable />
        </div>
    )
}

export default PureHerbs;
