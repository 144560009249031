import { growingData } from "./data";
import Fade from 'react-reveal/Fade';

function Growing() {
    return (
        <div className="my-[5rem] mx-[2rem]
        sm:m-[5rem]
        2xl:max-w-[1360px] 2xl:mx-auto
        ">
            {
                growingData.map((ele, i) => {
                    return <div
                        key={i}
                        className="flex flex-col-reverse gap-[6rem] items-center
                        lg:flex-row
                        "
                    >
                        <Fade left>
                            <div className="flex flex-col gap-[.5rem]">
                                <div className="w-full font-serif flex flex-col gap-[.5rem]
                                lg:w-[60%]
                                ">
                                    <p className="font-semibold text-[#6CAAA8] text-[1.7rem]">{ele.heading}</p>
                                </div>
                                <div className="text-slate-400 text-[1.05rem]">
                                    {
                                        (ele.content).map((contentEle, i) => {
                                            return <ul
                                                key={i}
                                                className="list-disc list-inside"
                                            >
                                                {typeof contentEle !== "object" ?
                                                    <li>{contentEle}</li>
                                                    :
                                                    Object.entries(contentEle).map(([key, value], i) => (
                                                        <div key={i}>
                                                            <li>{key}</li>
                                                            <ul className="list-disc list-inside flex flex-col pl-[1.3rem]">
                                                                {value.map((item, l) => (
                                                                    <li key={l}>{item}</li>
                                                                ))}
                                                            </ul>
                                                        </div>
                                                    ))
                                                }
                                            </ul>
                                        })
                                    }
                                </div>
                            </div>
                        </Fade>
                        <Fade right>
                            <div className="w-full h-[20rem]
                            lg:w-[40%] lg:h-full
                            ">
                                <img
                                    src={ele.img}
                                    alt="error"
                                    className="w-full h-full"
                                />
                            </div>
                        </Fade>
                    </div>
                })
            }
        </div>
    )
}

export default Growing;
