import { supportData } from "./data";

function Support() {
    return (
        <div className="my-[8rem] mx-[2rem] flex justify-center flex-wrap
        sm:m-[8rem]
        lg:mt-[15rem]
        xl:flex-row xl:flex-nowrap
        2xl:max-w-[1360px] 2xl:mx-auto
        ">
            {
                supportData.map((ele, i) => {
                    return <div key={i} className="p-[2rem] flex flex-col w-[18rem] gap-[.5rem] text-center items-center font-serif
                    lg:w-[25rem]
                    xl:w-fit
                    ">
                        <span>{ele.img}</span>
                        <p className="font-bold text-[1.1rem] text-[#6CAAA8]">{ele.heading}</p>
                        <p className="text-slate-400">{ele.content}</p>
                    </div>
                })
            }
        </div>
    )
}

export default Support;
