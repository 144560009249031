import { headerData } from "./data"
import logo from "./ashwanhealthcareLogo.webp"
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";

function Header() {

    let location = useLocation()

    return (
        <div className="shadow-md bg-[#6CAAA8] h-[6.5rem] sticky top-0 z-10 hidden flex-row justify-around items-center
        md:flex
        ">
            <div className="w-1/6
            2xl:w-[250px]
            ">
                <Link to={"/"}><img src={logo} alt="error" className="cursor-pointer" /></Link>
            </div>
            <div className="flex flex-row gap-[2rem] font-serif text-[1.1rem]">
                {
                    headerData.map((ele, i) => {
                        return <Link to={ele.path}><p key={i} className={`cursor-pointer text-white hover:text-black ${location.pathname === ele.path ? "!text-black" : ''}`}>{ele.name}</p></Link>
                    })
                }
            </div>
        </div>
    )
}

export default Header;
