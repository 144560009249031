import { Zoom, Flip } from "react-reveal"
import { counterData } from "./data";
import CountUp, { useCountUp } from 'react-countup';


function ExpertsAndTechnologies() {

    useCountUp({
        ref: 'counter',
        end: 1234567,
        enableScrollSpy: true,
        scrollSpyDelay: 1000,
        duration: 10
    }, []);


    return (
        <div className="my-[8rem] px-[2rem] pt-[5rem] bg-[#6CAAA814] flex flex-col gap-[5rem] h-[88rem]
        sm:h-[55rem] sm:mx-[0rem]
        lg:h-[30rem] lg:gap-[7rem]
        ">
            <div className="font-serif flex flex-col gap-[.5rem] items-center justify-center
            2xl:max-w-[1360px] 2xl:mx-auto
            ">
                <Zoom>
                    <img src="https://kamleshyadav.com/html/pure-ayurveda/html/assets/images/herbal.svg" alt="error" />
                </Zoom>
                <Flip top><p className="font-bold w-full text-center font-serif text-[2rem]
                sm:w-[40rem]
                ">Trusted Pharma Experts and Latest Pharma Technologies</p></Flip>
                <Flip top><p className="text-[#6CAAA8] font-semibold text-[1.5rem]">Our Achievements</p></Flip>
            </div>
            <div className="flex flex-wrap flex-col justify-center items-center text-center gap-[2.5rem]
            sm:flex-row
            ">
                {
                    counterData.map((ele, i) => {
                        return <div
                            key={i}
                            className="shadow-md flex flex-col justify-center items-center w-[50%] h-[12rem] bg-white rounded-[1.2rem] font-serif
                            sm:w-[12rem]
                            md:w-[20rem]
                            lg:w-[12rem]
                            xl:w-[15rem]"
                        >
                            <CountUp className="text-[2rem] font-bold text-serif"
                                start={0} end={ele.count} enableScrollSpy />
                            <p className="text-[1.05rem] text-serif">{ele.name}</p>
                        </div>
                    })
                }
            </div>
        </div>
    )
}

export default ExpertsAndTechnologies;
