import React from 'react';
import { contactData } from "./data";
import { Fade, Zoom } from "react-reveal"

function ContactDetails() {
    return (
        <div className='bg-[#6CAAA814]'>
            <div className='flex py-[5rem] px-[2rem] flex-wrap font-serif justify-between gap-[2rem]
        sm:p-[5rem]
        2xl:max-w-[1360px] 2xl:mx-auto
        '>
                {
                    contactData.map((item, index) => (
                        <Fade bottom>
                            <div
                                key={index}
                                className={`${index === 0 ? "w-full mb-[2rem]" : "w-full sm:w-[20rem]"}`}
                            >
                                <p className='font-bold text-[1.4rem] mb-[.5rem]'>{item.heading}</p>
                                {Array.isArray(item.content) ? (
                                    <div>
                                        {item.content.map((contentItem, contentIndex) => (
                                            <div key={contentIndex}>
                                                <p className='text-slate-400'>{contentItem}</p>
                                            </div>
                                        ))}
                                    </div>
                                ) : (
                                    <div className='flex flex-col gap-[1rem]'>
                                        <p className='text-slate-400'>{item.content}</p>
                                        {item.map && <Zoom><div dangerouslySetInnerHTML={{ __html: item.map }} /></Zoom>}
                                    </div>
                                )
                                }
                            </div>
                        </Fade>
                    ))
                }
            </div>
        </div >
    );
}

export default ContactDetails;
