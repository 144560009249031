import { Button } from "@mui/material";
import { productNormsData } from "./data";
import ProductNormsImg from "./productNormsImg.jpg"
import { Link } from "react-router-dom"

function ProductNorms() {
    return (
        <div className="bg-[#6CAAA814]">
            <div className="py-[5rem] px-[2rem] flex flex-col items-center gap-[5rem]
        sm:p-[5rem]
        lg:flex-row
        2xl:max-w-[1360px] 2xl:mx-auto
        ">
                <div>
                    <img
                        src={ProductNormsImg}
                        alt="error"
                        className="w-full h-[20rem]
                    lg:h-fit
                    "
                    />
                </div>
                <div className="w-full
            lg:w-[50%]
            ">
                    {
                        productNormsData.map((ele, i) => {
                            return <div key={i} className="font-serif flex flex-col gap-[.5rem]">
                                <p className="font-bold text-[1.2rem]">{ele.subHeading}</p>
                                <p className="font-extrabold text-[#6CAAA8] text-[1.7rem]">{ele.heading}</p>
                                <p className="text-slate-400 text-[1.1rem]">{ele.content}</p>
                            </div>
                        })
                    }
                    <div className="mt-[1.5rem]">
                        <Link to={"/about"}><Button
                            variant="outlined"
                            sx={{
                                borderColor: "#6CAAA814",
                                color: "#6CAAA8",
                                "&:hover": {
                                    borderColor: "#6CAAA8",
                                }
                            }}
                        >Read more</Button></Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProductNorms;
