export const classicalAyurvedaData = [
    {
        heading: "Ancient Wisdom",
        content: `Classical Ayurveda draws upon the wisdom of ancient Indian texts, particularly the Vedas and texts like Charaka Samhita and Sushruta Samhita.

        These texts provide a comprehensive framework for understanding the principles of health, disease, and well-being.`
    },
    {
        heading: "Three Doshas",
        content: `Ayurveda revolves around the concept of three doshas: Vata, Pitta, and Kapha.

        These doshas represent different combinations of the five elements (earth, water, fire, air, and ether) and play a crucial role in maintaining balance in the body.`
    },
    {
        heading: "Individualized Treatment",
        content: `Classical Ayurveda follows a personalized approach to healthcare.

        Each person's constitution, or Prakriti, is determined by the predominance of the doshas in their body. Treatment and dietary recommendations are tailored to an individual's unique constitution.`
    },
    {
        heading: "Herbal Remedies",
        content: `Herbal medicine is a cornerstone of Classical Ayurveda.

        Ayurvedic practitioners use a vast array of herbs to create formulations that address various health concerns. These herbs are prepared in specific ways to enhance their effectiveness.`
    },
    {
        heading: "Panchakarma",
        content: `Panchakarma is a comprehensive detoxification and rejuvenation therapy in Ayurveda.

        It involves various cleansing procedures to remove toxins and rejuvenate the body, mind, and spirit.`
    },
    {
        heading: "Holistic Wellness",
        content: `Classical Ayurveda goes beyond treating physical ailments and emphasizes mental and spiritual well-being.

        Practices such as yoga, meditation, and pranayama are integrated into the Ayurvedic lifestyle.`
    },
    {
        heading: "Seasonal Living",
        content: `Ayurveda recognizes the influence of the seasons on our well-being.

        Seasonal recommendations for diet and lifestyle help individuals stay in harmony with their environment.`
    },
    {
        heading: "Prevention and Longevity",
        content: `Ayurveda is not only about curing diseases but also about preventing them.

        It offers guidance on daily routines (Dinacharya) to maintain health and promote longevity.`
    },
    {
        heading: "Modern Relevance",
        content: `While rooted in ancient traditions, Classical Ayurveda continues to be relevant in modern healthcare.

        Many of its principles align with holistic and integrative medicine approaches.`
    },
    {
        heading: "Qualified Practitioners",
        content: `To benefit from Classical Ayurveda, it is essential to seek guidance from qualified Ayurvedic practitioners who have undergone rigorous training.`
    }
]