import { aboutAyurvedaData } from "./data"
import Fade from 'react-reveal/Fade';

function AboutAyurveda() {
    return (
        <div className="my-[5rem] mx-[2rem]
        lg:m-[5rem]
        2xl:max-w-[1360px] 2xl:mx-auto
        ">
            {
                aboutAyurvedaData.map((ele, i) => {
                    return <div key={i} className="flex flex-col items-center gap-[6rem]
                    lg:flex-row
                    ">
                        <Fade left>
                            <div className="w-full h-[20rem]
                            lg:w-[40%] lg:h-full
                            ">
                                <img
                                    src={ele.img}
                                    alt="error"
                                    className="w-full h-full shadow-md"
                                />
                            </div>
                        </Fade>
                        <Fade right>
                            <div className="w-full font-serif flex flex-col gap-[.5rem]
                            lg:w-[60%]
                            ">
                                <p className="font-semibold text-[#6CAAA8] text-[1.7rem]">{ele.heading}</p>
                                <p className="text-slate-400 text-[1.05rem]">{ele.content}</p>
                            </div>
                        </Fade>
                    </div>
                })
            }
        </div>
    )
}

export default AboutAyurveda;
