import glanceImg1 from "./images/glanceImg1.jpg"
import glanceImg2 from "./images/glanceImg2.jpg"
import glanceImg3 from "./images/glanceImg3.jpg"
import glanceImg4 from "./images/glanceImg4.jpg"
import glanceImg5 from "./images/glanceImg5.jpg"
import glanceImg6 from "./images/glanceImg6.jpg"
import glanceImg7 from "./images/glanceImg7.jpg"


export const glanceData = [
    {
        img: glanceImg1,
        heading: "Introduction",
        content: "Our pharmaceutical company is committed to enhancing the quality of life through the development, manufacturing, and distribution of innovative and high-quality pharmaceutical products. we have established ourselves as a trusted name in the healthcare industry."
    },
    {
        img: glanceImg2,
        heading: "Research and development",
        content: "At the heart of our success is our state-of-the-art research and development center, where our dedicated team of scientists and researchers work tirelessly to discover new drugs and improve existing formulations. We invest heavily in cutting-edge technology and collaborate with leading academic institutions to drive innovation."
    },
    {
        img: glanceImg3,
        heading: "Product Portfolio",
        content: "We take pride in the quality and efficacy of our medicines, which are manufactured in compliance with international quality standards."
    },
    {
        img: glanceImg4,
        heading: "Global Presence",
        content: "We have a global presence with operations in over 5 countries. Our commitment to ensuring access to healthcare knows no boundaries. We actively contribute to improving public health by providing affordable and accessible medications."
    },
    {
        img: glanceImg5,
        heading: "Sustainability",
        content: "We are deeply committed to environmental sustainability and corporate responsibility. Our initiatives aim to reduce our carbon footprint, minimize waste, and promote sustainable sourcing of raw materials. We work closely with communities to ensure that our activities have a positive impact on the environment."
    },
    {
        img: glanceImg6,
        heading: "Quality Assurance",
        content: "Quality is at the core of everything we do. Our manufacturing facilities are equipped with the latest technology and adhere to the most stringent quality control processes. We are proud to have received numerous quality certifications that reflect our unwavering dedication to product safety and efficacy."
    },
    {
        img: glanceImg7,
        heading: "Future Outlook",
        content: "As we look to the future, we remain dedicated to advancing healthcare solutions, improving patient outcomes, and contributing to the well-being of communities around the world. Our ongoing commitment to research and development, sustainability, and ethical business practices will continue to drive our success and make a meaningful difference in the healthcare sector."
    }
]