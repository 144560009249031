// import PersonIcon from '@mui/icons-material/Person';
// import LocalMallIcon from '@mui/icons-material/LocalMall';
// import DashboardIcon from '@mui/icons-material/Dashboard';
// import CategoryIcon from '@mui/icons-material/Category';
// import ViewCarouselIcon from '@mui/icons-material/ViewCarousel';
// import LocalOfferIcon from '@mui/icons-material/LocalOffer';
// import LogoutIcon from '@mui/icons-material/Logout';
// import ShowProducts from '../../pages/product/showProducts/ShowProducts';
// import AddProducts from '../../pages/product/addProducts/AddProducts';
// import AddBanners from '../../pages/banner/addBanners/AddBanners';
// import ShowBanners from '../../pages/banner/showBanners/ShowBanners';
// import OfferBannersList from '../../pages/offerBanner/offerBannersList/OfferBannersList'
// import AddOfferBanners from '../../pages/offerBanner/addOfferBanners/AddOfferBanners';
// import CustomersList from '../../pages/customers/CustomersList';
// import NoteIcon from '@mui/icons-material/Note';
// import Footer from '../../pages/footer/Footer';
// import LogOut from '../../pages/logOut/LogOut';

export const menuList = [
    {
        name: "Home",
        // icon: <DashboardIcon />,
        path: "/"
    },
    {
        name: "About",
        // icon: <ViewCarouselIcon />,
        path: "/about"
    },
    {
        name: "Our Business",
        // icon: <LocalOfferIcon />,
        arr: [
            {
                name: "Ayurveda",
                path: "/ourBusiness/ayurveda",
            },
            {
                name: "Nutraceutical",
                path: "/ourBusiness/Nutraceutical",
            }
        ]
    },
    {
        name: "Products",
        // icon: <CategoryIcon />,
        arr: [
            {
                name: "Patent Medicine",
                path: "/products/patentMedicine",
            },
            {
                name: "Classical Medicine",
                path: "/products/classicalMedicine",
            },
            {
                name: "Pure Herbs",
                path: "/products/pureHerbs",
            }
        ]
    },
    {
        name: "Operations",
        // icon: <NoteIcon />,
        path: "/operations",
    },
    {
        name: "Contact Us",
        // icon: <LogoutIcon />,
        path: "/contactUs",
    }
]