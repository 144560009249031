import { productsData } from "./data";
import Dropdown from "../../components/dropdown/Dropdown";

function Products() {
    return (
        <div>
            <Dropdown name={"Products"} data={productsData} />
        </div>
    );
}

export default Products;