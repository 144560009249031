import { classicalAyurvedaData } from "./data";
import Fade from 'react-reveal/Fade';

function ClassicalAyurveda() {
    return (
        <div className="mx-[2rem] my-[10rem] flex flex-col gap-[2rem]
        sm:mx-[5rem]
        2xl:max-w-[1360px] 2xl:mx-auto
        ">
            <div className="text-[2rem] text-center px-[2rem] font-bold font-serif text-[#6CAAA8]
            sm:px-[5rem]
            md:px-[10rem]
            lg:px-[15rem]
            xl:px-[20rem]
            ">
                <p>Classical Ayurveda: The Ancient Art of Healing</p>
            </div>
            <div className="flex flex-wrap gap-[2rem] justify-center">
                {
                    classicalAyurvedaData.map((ele, i) => {
                        return <Fade bottom><div
                            key={i}
                            className="shadow-md p-[1.2rem] font-serif w-[23rem]
                            sm:w-[18rem]
                            lg:w-[23rem]
                            "
                        >
                            <Fade left><p className="font-bold mb-[.5rem] text-[#6CAAA8] text-[1.4rem]">{ele.heading}</p></Fade>
                            <Fade right><p className="text-slate-400 text-[1.05rem]">{ele.content}</p></Fade>
                        </div></Fade>
                    })
                }
            </div>
        </div>
    )
}

export default ClassicalAyurveda;
