import OurStoryImg1 from "./images/ourStoryImg1.webp"
import OurStoryImg2 from "./images/ourStoryImg2.webp"
import OurStoryImg3 from "./images/ourStoryImg3.webp"
import OurStoryImg4 from "./images/ourStoryImg4.webp"
import OurStoryImg5 from "./images/ourStoryImg5.webp"
import OurStoryImg6 from "./images/ourStoryImg6.webp"

export const storyData = [
    {
        img: [OurStoryImg1, OurStoryImg2, OurStoryImg3, OurStoryImg4, OurStoryImg5, OurStoryImg6],
        heading: "We Are Here With 9 Years Of Experience",
        subHeading: "About Us",
        icon: "https://kamleshyadav.com/html/pure-ayurveda/html/assets/images/herbal.svg",
        content: "Ashwan Healthcare company for the last six years, of ayurvedic medicine and nutraceuticals sector operated by expert doctor's of ayurveda. The institution is in the lap of nature, in the mountain range of the himalaya, and taposthali of ancient ayurveda physician maharishi charak.and the birthplace of emperor bharat, (whose name bhartbasha) and situated in maharishi kanv's city kotdwar who was great indian philosopher, where rare and precious herbs are found and easily accessible and from these herb's high quality medicines are manufactured."
    },
]