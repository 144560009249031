import { awardsAndRecognitionData } from "./data";
import { Link } from "react-router-dom"
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useState, useEffect } from "react";

function AwardsAndRecognition() {

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    let slidesToShow = 3;
    let imgWidth = "350px";

    if (windowWidth <= 1235) {
        slidesToShow = 2;
        imgWidth = "450px";
    }
    if (windowWidth <= 1080) {
        imgWidth = "400px";
    }
    if (windowWidth <= 990) {
        imgWidth = "350px";
    }
    if (windowWidth <= 890) {
        imgWidth = "300px";
    }
    if (windowWidth <= 780) {
        imgWidth = "250px";
    }
    if (windowWidth <= 680) {
        slidesToShow = 1;
        imgWidth = "500px";
    }

    let settings = {
        arrows: false,
        dots: false,
        infinite: true,
        speed: 1000,
        autoplay: true,
        slidesToShow: slidesToShow,
        slidesToScroll: 1,
        variableWidth: false,
        pauseOnHover: true
    };

    return (
        <div className="m-[2rem] flex flex-col gap-[2rem]
        sm:m-[5rem]
        2xl:max-w-[1360px] 2xl:mx-auto
        ">
            <div className="text-center font-bold text-[2rem] font-serif text-[#6CAAA8]">
                <p>Award Gallary</p>
            </div>
            <Slider {...settings} className="flex justify-around">
                {
                    awardsAndRecognitionData.map((ele, i) => {
                        return <div key={i} className="mt-[2rem]">
                            <Link to={ele.path} target="_blank"><img
                                src={ele.name}
                                alt="error"
                                className={`w-[${imgWidth}] h-[250px]`}
                            /></Link>
                        </div>
                    })
                }
            </Slider>
        </div>
    )
}

export default AwardsAndRecognition;
