import productsAndTableImg1 from "./images/productAndTableImg1.webp"
import productsAndTableImg2 from "./images/productAndTableImg2.webp"
import productsAndTableImg3 from "./images/productAndTableImg3.webp"
import productsAndTableImg4 from "./images/productAndTableImg4.webp"
import productsAndTableImg5 from "./images/productAndTableImg5.webp"
import productsAndTableImg6 from "./images/productAndTableImg6.webp"
import productsAndTableImg7 from "./images/productAndTableImg7.webp"


export const productsAndTableData = [
    {
        img: productsAndTableImg1,
        heading: "UTI- CR",
        tableData: [
            {
                name: "Magnesium Citrate",
                value: "978mg"
            },
            {
                name: "D-Mannose",
                value: "300mg"
            },
            {
                name: "Cranberry Extract",
                value: "200mg"
            }
        ]
    },
    {
        img: productsAndTableImg2,
        heading: "ASTOCAL D3",
        tableData: [
            {
                name: "Calcium Ascorbate",
                value: "250mg"
            },
            {
                name: "Vitamin D3 (Calciferol)",
                value: "200I.U"
            },
            {
                name: "Flavoured Syrupy Base",
                value: "q.s"
            },
        ]
    },
    {
        img: productsAndTableImg3,
        heading: "ASTOMIN SYRUP",
        tableData: [
            {
                name: "Betacarotene",
                value: "2000mcg"
            },
            {
                name: "L-Lysine HCI",
                value: "50mcg"
            },
            {
                name: "Ascorbic Acid",
                value: "10mcg"
            },
            {
                name: "Vitamin E Acetate",
                value: "4.5mcg"
            },
            {
                name: "D-Penthenol",
                value: "0.98mcg"
            },
            {
                name: "Niacinamide",
                value: "5.0mg"
            },
            {
                name: "Vitamin B1",
                value: "0.99 mg"
            },
            {
                name: "Vitamin B2",
                value: "0.99 mg"
            },
            {
                name: "Vitamin B6",
                value: "0.49 mg"
            },
            {
                name: "Vitamin A Palmitate",
                value: "420.0 mcg"
            },
            {
                name: "Folic Acid",
                value: "48 mcg"
            },
            {
                name: "Vitamin B12",
                value: "0.48 mcg"
            },
            {
                name: "Zinc Sulphate",
                value: "4.5 mg"
            },
            {
                name: "Copper Sulphate",
                value: "1.0 mg"
            },
            {
                name: "Sodium Selenate",
                value: "100.0 mcg"
            },
            {
                name: "Potassium Lodide",
                value: "50mcg"
            }
        ]
    },
    {
        img: productsAndTableImg4,
        heading: "VITAMIN - C",
        tableData: [

        ]
    },
    {
        img: productsAndTableImg5,
        heading: "MULTIVITAMIN TABLETS",
        tableData: [
            {
                name: "Vitamin C",
                value: "400mg - 100%"
            },
            {
                name: "Vitamin B",
                value: "12mg - 75%"
            },
            {
                name: "Vitamin E",
                value: "8mg - 80%"
            },
            {
                name: "Vitamin B",
                value: "1.5mg - 75%"
            },
            {
                name: "Vitamin B2",
                value: "1.4mg - 82.35%"
            },
            {
                name: "Vitamin B1",
                value: "1mg - 75.43%"
            }
        ]
    },
    {
        img: productsAndTableImg6,
        heading: "NICKZYME",
        tableData: [
            {
                name: "Diastase (1:1200) (Fungal Diastase derived from Aspergillus oryzae. Digests not less than 60 g of cooked starch)",
                value: "50mg"
            },
            {
                name: "Alpha-galactosidase",
                value: "25mg"
            },
            {
                name: "Pepsin (1:3000)",
                value: "10mg"
            },
            {
                name: "(Digests not less than 30 g) Flavoured liquid base",
                value: "q.s"
            }
        ]
    },
    {
        img: productsAndTableImg7,
        heading: "Nick Pro",
        tableData: [

        ]
    }
]