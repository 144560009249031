import { productsAndTableData } from "./data";
import Fade from 'react-reveal/Fade';

function ProductsAndTable() {
    return (
        <div className="my-[5rem] mx-[2rem] flex flex-col font-serif gap-[3rem]
        sm:m-[5rem]
        2xl:max-w-[1360px] 2xl:mx-auto
        ">
            {
                productsAndTableData.map((ele, i) => {
                    return <Fade bottom><div
                        key={i}
                        className="flex flex-col gap-[2rem] justify-between bg-[#6CAAA814] shadow-lg p-[2rem] items-center
                        lg:flex-row lg:gap-[0rem]
                        "
                    >
                        <Fade left>
                            <div className="font-bold text-[1.5rem] text-[#6CAAA8] flex flex-col gap-[1.5rem]">
                                <p>{ele.heading}</p>
                                <img
                                    src={ele.img}
                                    alt="error"
                                    className="w-[50rem] h-[20rem]
                                    lg:w-[20rem]
                                    "
                                />
                            </div>
                        </Fade>
                        <Fade right>
                            <table
                                className="text-slate-500 w-full
                                lg:w-[50%]
                                "
                            >
                                <tbody>
                                    {
                                        Array.isArray(ele.tableData) && ele.tableData.length !== 0
                                            ?
                                            ele.tableData.map((ele, i) => {
                                                return <tr key={i}>
                                                    <td className="border-[.1rem] p-[.5rem]">{ele.name}</td>
                                                    <td className="border-[.1rem] p-[.5rem]">{ele.value}</td>
                                                </tr>
                                            })
                                            :
                                            null
                                    }
                                </tbody>
                            </table>
                        </Fade>
                    </div>
                    </Fade>
                })
            }
        </div>
    )
}

export default ProductsAndTable;