import ManufacturingExcellence from "./manufacturingExcellence/ManufacturingExcellence";
import QualityControl from "./qualityControl/QualityControl";
import ResearchAndDevelopment from "./researchAndDevelopment/ResearchAndDevelopment";
import Banner from "../../components/banner/Banner";

function Operations() {
  return (
    <div>
      <Banner heading={"Operations"} />
      <ManufacturingExcellence />
      <QualityControl />
      <ResearchAndDevelopment />
    </div>
  )
}

export default Operations;
