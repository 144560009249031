import researchAndDevelopmentImg1 from "./images/researchAndDevelopmentImg1.avif"
import researchAndDevelopmentImg2 from "./images/researchAndDevelopmentImg2.avif"
import researchAndDevelopmentImg3 from "./images/researchAndDevelopmentImg3.avif"
import researchAndDevelopmentImg4 from "./images/researchAndDevelopmentImg4.webp"
import researchAndDevelopmentImg5 from "./images/researchAndDevelopmentImg5.webp"


export const researchAndDevelopmentData = [
    {
        img: researchAndDevelopmentImg1,
        heading: "Innovation and Discovery",
        content: "Our research and development team is dedicated to innovation and discovery. We continuously explore new possibilities in Ayurvedic science to create groundbreaking products that enhance health and well-being."
    },
    {
        img: researchAndDevelopmentImg2,
        heading: "Advanced Laboratories",
        content: "Our state-of-the-art laboratories are equipped with cutting-edge technology, allowing our scientists to conduct in-depth research and testing. We are committed to maintaining the highest scientific standards."
    },
    {
        img: researchAndDevelopmentImg3,
        heading: "Collaborative Partnerships",
        content: "We actively collaborate with experts and institutions in the field of Ayurvedic research. These partnerships foster a dynamic environment that encourages knowledge exchange and accelerates our research efforts."
    },
    {
        img: researchAndDevelopmentImg4,
        heading: "Association 1",
        content: "Our first scientific association is dedicated to cutting-edge research in the field of [specify the scientific field]. We collaborate with leading experts and researchers to advance knowledge and innovation in this domain."
    },
    {
        img: researchAndDevelopmentImg5,
        heading: "Association 2",
        content: "The second association focuses on [describe the area of focus, e.g., medical research, environmental conservation, etc.]. We work together to address critical challenges and contribute to meaningful solutions in this area."
    }
]