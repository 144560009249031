import Banner from "../../components/banner/Banner";
import AwardsAndRecognition from "./awardsAndRecognition/AwardsAndRecognition";
import Certificate from "./certificates/Certificate";
import Glance from "./glance/Glance";
import OurStory from "./ourStory/OurStory";
import ExpertsAndTechnologies from "../home/expertsAndTechnologies/ExpertsAndTechnologies"
import Biography from "./biography/Biography";

function About() {
  return (
    <div>
      <Banner heading={"About Us"} />
      <OurStory />
      <ExpertsAndTechnologies />
      <Glance />
      <AwardsAndRecognition />
      <Certificate />
      <Biography />
    </div>
  )
}

export default About;
